import React from "react";
import ReactDOM from "react-dom/client";
import RoutesPosgrados from "./hooks/routesPosgrados";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import NewHome from "./pages/newHome";
import Oferta from "./pages/Oferta";
import Floating from "./components/Floating";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Rendicion from "./pages/Rendicion";
import Contacto from "./pages/Contacto";
import ModeloDistancia from "./pages/ModeloDistancia";
import Presencial from "./pages/Presencial";
import ModeloPresencial from "./pages/ModeloPresencial";
import Enlinea from "./pages/Enlinea";
import Herramientas from "./pages/Herramientas";

import Posgrado from "./pages/Posgrado";
import FormacionPermanente from "./pages/FormacionPermanente";
import Decidesermas from "../src/newPages/DecideSerMas";
import Imagen from "./pages/Imagen";
import Alumni from "./pages/Alumni";

import "./index.css";
import Gem from "./pages/landings/Gem";
import ParqueCientifico from "./pages/ParqueCientifico";
import Calidad from "./pages/Calidad";
import PruebaAdmision from "./pages/PruebaAdmision";

import Reingreso from "./newPages/alumnos/Reingreso";
import Competencias from "./newPages/alumnos/Competencias";
import SegundaLenguaa from "./newPages/alumnos/SegundaLengua";
import NewMatriculas from "./newPages/Matriculas";
import Newtitulo from "./newPages/alumnos/TituloValidable";

import NewGober from "./newPages/Gobernanza";

import NewTec from "./newPages/oferta/Tecnologias";
import NewTrasparencia from "./newPages/Transparencia";
import Internacional from "./newPages/alumnos/Internacional";

import MisionSemanaSanta from "./newPages/alumnos/MisionSemanaSanta";
import AcercadePlan from "./newPages/AcercadePlan";
import FernandoRielo from "./newPages/FernandoRielo";
import AlianzaUPV from "./newPages/alumnos/AlianzaUPV";
import Edes from "./newPages/oferta/Edes";
import MIA from "./newPages/alumnos/MIA";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Floating></Floating>
      <Header></Header>
      <Routes>
        <Route index element={<NewHome></NewHome>} />
        <Route path="/oferta" element={<Oferta />} />
        <Route path="/decidesermas" element={<Decidesermas />} />
        <Route path="/alumni" element={<Alumni />} />
        <Route path="/imagen" element={<Imagen />} />
        <Route path="/rendicioncuentas" element={<Rendicion />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/presencial" element={<Presencial />} />
        <Route path="/estudiar-en-loja" element={<ModeloPresencial />} />
        <Route path="/en-linea" element={<Enlinea />} />
        <Route path="/estudiar-en-linea" element={<ModeloDistancia />} />
        <Route path="/herramientas" element={<Herramientas />} />

        <Route path="/posgrados" element={<Posgrado />} />
        <Route path="/formacionpermanente" element={<FormacionPermanente />} />
        <Route path="/gem" element={<Gem />} />
        <Route path="/parque-cientifico" element={<ParqueCientifico />} />

        <Route path="/calidad" element={<Calidad />} />
        <Route path="/presencial/prueba" element={<PruebaAdmision />} />

        <Route path="/retoma-tus-estudios" element={<Reingreso />} />

        <Route path="/competencias" element={<Competencias />} />
        <Route
          path="/segunda-lengua"
          element={<SegundaLenguaa></SegundaLenguaa>}
        />
        <Route path="/matriculas" element={<NewMatriculas></NewMatriculas>} />
        <Route path="/validacion-titulo" element={<Newtitulo></Newtitulo>} />
        <Route path="/gobernanza" element={<NewGober></NewGober>} />
        <Route path="/acercade" element={<AcercadePlan></AcercadePlan>} />

        <Route path="/tecnologias" element={<NewTec></NewTec>} />
        <Route path="/edes" element={<Edes></Edes>} />
        <Route
          path="/transparencia"
          element={<NewTrasparencia></NewTrasparencia>}
        />
        <Route
          path="/new-internacional"
          element={<Internacional></Internacional>}
        />

        <Route
          path="/mision-semana-santa"
          element={<MisionSemanaSanta></MisionSemanaSanta>}
        />

        <Route
          path="/fernando-rielo"
          element={<FernandoRielo></FernandoRielo>}
        />

        <Route path="/alianza-upv" element={<AlianzaUPV></AlianzaUPV>} />

        <Route path="/acompanamiento-integral" element={<MIA></MIA>} />
      </Routes>

      <RoutesPosgrados></RoutesPosgrados>

      {/* <ApolloProvider client={client}>
        <RoutesPosgrados></RoutesPosgrados>
      </ApolloProvider> */}
      <Footer></Footer>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
