import Block2Columns from "../../components/Block2ColumnsText";
import DataFetcherTemplate from "../../hooks/fetchTemplate";
import BannerGeneral from "../../components/BannerGeneral";
import BlockImageCabecera from "../../components/BlockImageCabecera";
import Block4ColumnsText from "../../components/Block4ColumnsText";
import Block3Columns from "../../components/Block3Columns";
import BlockRightImage from "../../components/BlockRightImage";
import BlockLeftImage from "../../components/BlockLeftImage";
import ImageGallery from "../../components/ImageGallery";
import ModalFixed from "../../components/ModalFixed";
import Notification from "../../components/Notification";

function MIA() {
  const DataDisplay = ({ data }) => (
    <>
      {data.map((item, index) => (
        <>
          <div className="layout8 margin padding">
            <center>
              <div
                className="width700"
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_portada,
                }}
              />
              <div className="icon350">
                <img src={item.field_portada}></img>
              </div>
            </center>
          </div>

          <Notification
            content={
              <div className="padding2">
                <p className="iconsize20">👣🚀💪🏆🎓</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque1,
                  }}
                />
              </div>
            }
          ></Notification>
          <BlockLeftImage
            src={item.field_imagen2}
            content={
              <>
                <p className="iconsize20">🎉🎓</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque2,
                  }}
                />
              </>
            }
          ></BlockLeftImage>

          <div className="graybg ">
            <BlockRightImage
              src={item.field_imagen4}
              content={
                <>
                  <p className="iconsize20">📚🤝🎓</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque4,
                    }}
                  />
                </>
              }
            ></BlockRightImage>

            <div className="layout8 margin">
              <center>
                <div
                  className="width700"
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque5,
                  }}
                />
              </center>
            </div>


<div className="padding">
            <Block4ColumnsText
              col1={
                <>
                  <div className="icon100">
                    <img src={item.field_imagen6} alt="utpl"></img>
                  </div>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque6,
                    }}
                  />{" "}
                </>
              }
              col2={
                <>
                  <div className="icon100">
                    <img src={item.field_imagen7} alt="utpl"></img>
                  </div>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque7,
                    }}
                  />{" "}
                </>
              }
              col3={
                <>
                  <div className="icon100">
                    <img src={item.field_imagen8} alt="utpl"></img>
                  </div>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque8,
                    }}
                  />{" "}
                </>
              }
              col4={
                <>
                  <div className="icon100">
                    <img src={item.field_imagen9} alt="utpl"></img>
                  </div>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque9,
                    }}
                  />{" "}
                </>
              }
            ></Block4ColumnsText></div>
          </div>

          <div className="padding">
            <div className="layout8">
              <center>
                <p className="iconsize20">🚀🏆🎓</p>
              </center>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque3,
                }}
              />
            </div>
          </div>
        </>
      ))}
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-mia.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default MIA;
